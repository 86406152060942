import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

import {
  TuiInputModule,
  TuiInputNumberModule,
  TuiIslandModule,
  TuiToggleModule,
} from '@taiga-ui/kit';
import { TuiActiveZoneModule, TuiLetModule } from '@taiga-ui/cdk';
import { TuiButtonModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import { AsyncPipe, JsonPipe, NgForOf } from '@angular/common';
import { TuiTableModule } from '@taiga-ui/addon-table';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  delay,
  filter,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { fromFormControlValue } from '@shared/utils/observables';
import {
  CargoPlaceType,
  cargoPlaceTypesMap,
} from '@shared/constants/cargo-places';

import { roundDecimal } from '@shared/utils/math';
import { AlertService } from '@shared/services/alert.service';
import { Store } from '@ngxs/store';
import { RateService } from '@shared/services/rate.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CatalogsService } from '../../services/catalogs.service';
import { CargoService } from '../../services/cargo.service';
import { CargoState } from '../../store/cargo.state';
import { OrderGroupStateService } from './services/order-group-state.service';
import {
  CargoOrder,
  CargoOrderForm,
  CargoOrderGroup,
} from '../../../../types/cargo';
import { OrderComponent } from './components/order/order.component';
import { CargoPlaceQuantity } from '../../../../types/package-type';

type GroupedPallet = {
  place: string;
  quantity: number;
  cost: number;
  currencyLetter: string;
};

type OrderGroupExceptOrders = Omit<CargoOrderGroup, 'orders'>;

const NO_TYPE = '(без типа)';
@Component({
  selector: 'app-order-group',
  standalone: true,
  imports: [
    TuiIslandModule,
    TuiActiveZoneModule,
    TuiInputModule,
    ReactiveFormsModule,
    TuiTextfieldControllerModule,
    AutocompleteComponent,
    TuiToggleModule,
    TuiInputNumberModule,
    OrderComponent,
    NgForOf,
    AsyncPipe,
    TuiTableModule,
    TuiLetModule,
    TuiButtonModule,
    JsonPipe,
  ],
  providers: [OrderGroupStateService],
  templateUrl: './order-group.component.html',
  styleUrl: './order-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderGroupComponent implements OnInit {
  orderGroupForm = this.fb.group({
    id: this.fb.control<Nullable<number>>(null),
    cargoId: this.fb.control<Nullable<number>>(null),
    farmJson: this.fb.group({
      id: this.fb.control<Nullable<number>>(null),
      flowerTypesJson: this.fb.control<string[]>([]),
      addressesJson: this.fb.control<string[]>([]),
      name: '',
      imported: this.fb.control<Nullable<boolean>>(null),
    }),
    clientJson: this.fb.group({
      id: this.fb.control<Nullable<number>>(null),
      name: '',
      inn: '',
      addressesJson: this.fb.control<string[]>([]),
    }),
    flowerType: this.fb.control<Nullable<string>>(null),
    imported: this.fb.control<Nullable<boolean>>({
      value: null,
      disabled: true,
    }),
    orders: this.fb.array<FormGroup<CargoOrderForm>>([]),
  });

  tableHeaders: Array<{ name: string; value: keyof GroupedPallet }> = [
    {
      name: '',
      value: 'place',
    },
    {
      name: '',
      value: 'quantity',
    },
    {
      name: '',
      value: 'cost',
    },
  ];

  addOrder$ = new Subject<void>();
  update$ = new Subject<void>();
  removeOrder$ = new Subject<number>();
  orderGroup$ = new BehaviorSubject<Nullable<OrderGroupExceptOrders>>(null);
  isActiveZone$ = new BehaviorSubject(false);
  isImport$ = fromFormControlValue(this.orderGroupForm.controls.imported);
  farmId$ = fromFormControlValue(
    this.orderGroupForm.controls.farmJson.controls.id,
  );

  clientId$ = fromFormControlValue(
    this.orderGroupForm.controls.clientJson.controls.id,
  );

  availableFlowerTypes$ = combineLatest([
    this.catalogs.flowerTypes$,
    fromFormControlValue(this.farmJson.controls.flowerTypesJson),
  ]).pipe(
    map(([flowerTypes, farmFlowerTypeIds]) => {
      if (
        !flowerTypes.length ||
        !farmFlowerTypeIds ||
        !farmFlowerTypeIds.length
      ) {
        return [];
      }
      return flowerTypes.filter(flowerType => {
        return farmFlowerTypeIds.includes(flowerType.id.toString());
      });
    }),
  );

  formData$: Observable<{
    isImport: Nullable<boolean>;
    farmId: Nullable<number>;
    clientId: Nullable<number>;
  }> = combineLatest([this.isImport$, this.farmId$, this.clientId$]).pipe(
    map(([isImport, farmId, clientId]) => ({ isImport, farmId, clientId })),
  );

  readonly typesMap = cargoPlaceTypesMap();

  trailerType$ = this.store
    .select(CargoState.getSelectedCargo)
    .pipe(map(cargo => cargo?.trailerType ?? ''));

  groupedPallets$ = merge(
    this.orders.valueChanges.pipe(startWith([])),
    this.trailerType$,
    this.orderGroupForm.controls.flowerType.valueChanges,
  ).pipe(
    map(() => this.orderGroupForm.getRawValue()),
    withLatestFrom(this.trailerType$),
    map(([group]) => {
      const orders = group.orders ?? [];
      const isImport = group.imported;
      const flowerType = group.flowerType;
      const totalPlaceQuantity = this.getQuantityByCargoPlaceType(orders);
      const totalPlaceWeight = this.getWeightByCargoPlaceType(orders);
      const places: GroupedPallet[] = Object.entries(totalPlaceQuantity).map(
        ([place, quantity]) => {
          const rateMapKey = this.rateService.getMapKey({
            cargoPlaceCode: place,
            flowerTypeId: flowerType,
            imported: isImport,
          });

          const rateDetails = this.catalogs.rateMap.get(rateMapKey);
          const rate = rateDetails?.rate ?? 0;
          const weight = totalPlaceWeight[place];
          const cost = rateDetails?.imported ? rate * weight : rate * quantity;
          return {
            place: this.typesMap.get(place)?.name ?? NO_TYPE,
            quantity: roundDecimal(quantity),
            currencyLetter: rateDetails?.currencyLetter ?? '',
            cost:
              Number.isNaN(cost) || !Number.isFinite(cost)
                ? 0
                : roundDecimal(cost),
          };
        },
      );
      return places;
    }),
    shareReplay(),
  );

  constructor(
    private readonly cargoService: CargoService,
    private readonly fb: FormBuilder,
    private readonly destroyRef: DestroyRef,
    private readonly cdr: ChangeDetectorRef,
    protected readonly catalogs: CatalogsService,
    private readonly alert: AlertService,
    private readonly orderGroupStateService: OrderGroupStateService,
    private readonly store: Store,
    private readonly rateService: RateService,
  ) {}

  onZoneChange(focused: boolean) {
    if (!focused) {
      this.update$.next();
    }
    this.isActiveZone$.next(focused);
  }

  onUpdate() {
    this.update$
      .pipe(
        delay(0),
        filter(() => !this.orderGroupForm.pristine),
        switchMap(() =>
          this.cargoService.updateOrders(
            this.orderGroupForm.controls.orders.getRawValue(),
          ),
        ),
        switchMap(() =>
          this.cargoService.updateOrderGroup(
            this.orderGroupForm.getRawValue() as CargoOrderGroup,
          ),
        ),
        tap(() => {
          this.alert.showSuccess();
          this.orderGroupForm.markAsUntouched();
          this.orderGroupForm.markAsPristine();
          this.cargoService.saveRecalculatedValues$.next();
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onAddOrderClick() {
    this.addOrder$
      .pipe(
        switchMap(() =>
          this.cargoService.createOrder(this.orderGroup$.value?.id ?? 0),
        ),
        withLatestFrom(this.isImport$),
        tap(([order, imported]) => {
          const cargoPlace = this.catalogs.cargoPlaceMap.get(
            CargoPlaceType.PALLET_US,
          );
          const group = this.fb.group({
            ...order,
            rateJson: this.fb.group(order.rateJson),
            packageTypeJson: this.fb.group(order.packageTypeJson),
            cargoPlaceJson:
              imported && cargoPlace
                ? this.fb.group(cargoPlace)
                : this.fb.group(order.cargoPlaceJson),
          });
          group.markAsTouched();
          group.markAsDirty();
          this.orders.push(group);
          this.setTouchedAndDirty();
          this.cdr.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onRemoveOrderClick() {
    this.removeOrder$
      .pipe(
        switchMap(id => this.cargoService.deleteOrder(id).pipe(map(() => id))),
        tap(id => {
          const orders = this.orders.getRawValue();
          const index = orders.findIndex(i => i.id === id);
          this.orders.removeAt(index);
          this.setTouchedAndDirty();
          this.cdr.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onImportChange() {
    this.orderGroupForm.controls.imported.valueChanges
      .pipe(
        tap(imported => {
          this.orderGroupForm.controls.orders.controls.forEach(group => {
            if (imported) {
              group.controls.cargoPlaceJson.reset();
              group.controls.rateJson.reset();
              group.patchValue({
                cargoPlaceQuantity: null,
                packageQuantity: null,
                packageQuantityLimit: null,
              });
            } else {
              group.patchValue({
                countryLetter: null,
                airWaybillNumber: null,
                precooling: null,
                weight: null,
              });
            }
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onFarmChange() {
    const id =
      this.orderGroupForm.controls.farmJson.controls.id.value?.toString() ?? '';
    this.setFarmDetails(id);
    this.clearClientCodes();
    this.setDefaultCargoPlaceIfImport();
  }

  onClientChange() {
    const id =
      this.orderGroupForm.controls.clientJson.controls.id.value?.toString() ??
      '';
    this.setClientName(id);
    this.clearClientCodes();
  }

  setTouchedAndDirty() {
    this.orderGroupForm.markAsTouched();
    this.orderGroupForm.markAsDirty();
  }

  setDefaultCargoPlaceIfImport() {
    if (this.orderGroupForm.controls.imported.getRawValue()) {
      const cargoPlace = this.catalogs.cargoPlaceMap.get(
        CargoPlaceType.PALLET_US,
      );
      this.orderGroupForm.controls.orders.controls.forEach(group => {
        if (cargoPlace) {
          group.controls.cargoPlaceJson.patchValue(cargoPlace);
        } else {
          group.controls.cargoPlaceJson.reset();
        }
      });
    }
  }

  clearClientCodes() {
    this.orderGroupForm.controls.orders.controls.forEach(group => {
      group.controls.buyerCode.setValue(null);
    });
  }

  get farmJson() {
    return this.orderGroupForm.controls.farmJson;
  }

  get clientJson() {
    return this.orderGroupForm.controls.clientJson;
  }

  setFarmDetails(farmId: Nullable<string>) {
    if (!farmId) {
      this.orderGroupForm.patchValue({
        flowerType: '',
        imported: null,
      });
      this.farmJson.reset();
    } else {
      const farm = this.catalogs.farmMap.get(farmId.toString());
      if (farm) {
        this.farmJson.patchValue(farm);
        this.orderGroupForm.patchValue({
          imported: farm.imported,
          flowerType: null,
        });
      } else {
        this.farmJson.reset();
      }
    }
    this.farmJson.markAsDirty();
  }

  setClientName(id: Nullable<string>) {
    if (!id) {
      this.clientJson.reset();
    } else {
      const client = this.catalogs.clientMap.get(id.toString());
      if (client) {
        this.clientJson.patchValue(client);
      } else {
        this.clientJson.reset();
      }
    }
    this.clientJson.markAsDirty();
  }

  get orders() {
    return this.orderGroupForm.controls.orders;
  }

  get ordersArray() {
    return this.orderGroupForm.controls.orders.controls as FormGroup[];
  }

  getQuantityByCargoPlaceType(orders: CargoOrder[]) {
    const typeQuantity: Record<string, number> = {};

    return orders.reduce((prev, curr) => {
      const type = curr.cargoPlaceJson.code || NO_TYPE;
      return {
        ...prev,
        [type]: (prev[type] ?? 0) + (curr.cargoPlaceQuantity ?? 0),
      };
    }, typeQuantity);
  }

  getWeightByCargoPlaceType(orders: CargoOrder[]) {
    const typeWeight: Record<string, number> = {};

    return orders.reduce((prev, curr) => {
      const type = curr.cargoPlaceJson.code || NO_TYPE;
      return {
        ...prev,
        [type]: (prev[type] ?? 0) + (curr.weight ?? 0),
      };
    }, typeWeight);
  }

  get columns() {
    return [...this.tableHeaders.map(c => c.value)];
  }

  ngOnInit() {
    this.onUpdate();
    this.onAddOrderClick();
    this.onRemoveOrderClick();
    this.onImportChange();
  }

  @Input() set orderGroup({ orders, ...rest }: CargoOrderGroup) {
    this.orderGroup$.next(rest);
    this.orderGroupForm.patchValue(rest, { emitEvent: false });
    this.orderGroupForm.controls.orders.clear({ emitEvent: false });
    const ordersForms = orders.map(order =>
      this.fb.group({
        ...order,
        packageQuantityLimit: this.fb.control({
          value: order.packageQuantityLimit,
          disabled: true,
        }),
        cargoPlaceQuantity: this.fb.control({
          value: order.cargoPlaceQuantity,
          disabled: true,
        }),
        rateJson: this.fb.group(order.rateJson),
        cargoPlaceJson: this.fb.group(order.cargoPlaceJson),
        packageTypeJson: this.fb.group({
          ...order.packageTypeJson,
          cargoPlaceQuantityJson: this.fb.control<
            Nullable<CargoPlaceQuantity[]>
          >(order.packageTypeJson.cargoPlaceQuantityJson),
        }),
      }),
    );

    ordersForms.forEach((form, index) => {
      this.orderGroupForm.controls.orders.push(form, {
        emitEvent: index === ordersForms.length - 1,
      });
    });
  }
}
