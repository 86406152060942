import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  DestroyRef,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import { CheckboxLabeledComponent } from '@shared/components/forms/checkbox-labeled/checkbox-labeled.component';
import { TuiInputModule, TuiInputNumberModule } from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiGroupModule,
  TuiHintModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import {
  BehaviorSubject,
  combineLatest,
  map,
  merge,
  Observable,
  shareReplay,
  startWith,
  Subject,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngxs/store';
import CatalogsState from '@store/catalogs/catalogs.state';
import { CheckboxComponent } from '@shared/components/forms/checkbox/checkbox.component';
import { roundDecimal } from '@shared/utils/math';
import {
  getCargoPlaceQuantityByTrailerType,
  TrailerType,
} from '@shared/constants/vehicles';
import { CargoOrderForm } from '../../../../../../types/cargo';
import { CatalogsService } from '../../../../services/catalogs.service';
import { CargoState } from '../../../../store/cargo.state';

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [
    AutocompleteComponent,
    CheckboxLabeledComponent,
    ReactiveFormsModule,
    TuiInputNumberModule,
    TuiTextfieldControllerModule,
    TuiButtonModule,
    AsyncPipe,
    TuiGroupModule,
    TuiInputModule,
    TuiActiveZoneModule,
    CheckboxComponent,
    TuiHintModule,
  ],
  templateUrl: './order.component.html',
  styleUrl: './order.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderComponent implements OnInit {
  @Input({ required: true }) last: boolean;
  @Input({ required: true }) index: number;
  @Output() create = new EventEmitter<void>();
  @Output() remove = new EventEmitter<number>();
  hasBox$: Observable<boolean>;
  isImport$ = new BehaviorSubject(false);
  farmId$ = new BehaviorSubject<Nullable<number>>(null);
  clientId$ = new BehaviorSubject<Nullable<number>>(null);
  update$ = new Subject<void>();

  countries = this.store.selectSignal(CatalogsState.getNsiCountries);
  @Input({ required: true }) orderForm: FormGroup<CargoOrderForm>;
  buyersOfSelectedFarm$ = combineLatest([
    this.farmId$,
    this.clientId$,
    this.catalogs.buyers$,
  ]).pipe(
    map(([farmId, clientId, buyers]) => {
      if (!farmId || !clientId) {
        return [];
      }
      return buyers.filter(
        buyer => buyer.farmId === farmId && buyer.clientId === clientId,
      );
    }),
  );

  trailerType$ = this.store
    .select(CargoState.getSelectedCargo)
    .pipe(map(cargo => cargo?.trailerType));

  cargoPlaceQuantityLimit = this.fb.control<Nullable<number>>({
    value: null,
    disabled: true,
  });

  constructor(
    private readonly fb: FormBuilder,
    private readonly destroyRef: DestroyRef,
    protected readonly catalogs: CatalogsService,
    private readonly store: Store,
  ) {}

  onZoneChange(focused: boolean) {
    if (!focused) {
      this.update$.next();
    }
  }

  onPackageTypeChange() {
    this.orderForm.controls.packageTypeJson.controls.code.valueChanges
      .pipe(
        tap(packageType => {
          this.updatePackageDetails(packageType);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onCargoPlaceTypeChange() {
    this.orderForm.controls.cargoPlaceJson.controls.code.valueChanges
      .pipe(
        withLatestFrom(this.trailerType$),
        tap(([type, trailerType]) => {
          this.updateCargoPlaceDetails(type, trailerType as TrailerType);
          this.clearPackageDetails();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  @Input({
    required: true,
    transform: (value: Nullable<boolean>) => value ?? false,
  })
  set isImport(value: boolean) {
    this.isImport$.next(value);
  }

  @Input({ required: true })
  set farmId(value: Nullable<number>) {
    this.farmId$.next(value);
  }

  @Input({ required: true })
  set clientId(value: Nullable<number>) {
    this.clientId$.next(value);
  }

  clearPackageDetailsIfCartSelected(palletType: string) {
    // TODO: как определять тележку ?
    // if (palletType === PalletType.CART) {
    //   this.orderForm.controls.box.setValue(false);
    //   this.orderForm.controls.packageTypeCode.setValue('');
    //   this.orderForm.controls.packageQuantity.setValue(0);
    // }
  }

  clearPackageDetails() {
    this.orderForm.patchValue({
      packageQuantityLimit: null,
      packageQuantity: null,
    });
  }

  getCalculatedQuantity(
    packageQuantityLimit: Nullable<number>,
    packageQuantity: Nullable<number>,
  ) {
    return packageQuantityLimit && packageQuantity
      ? roundDecimal(packageQuantity / Number(packageQuantityLimit))
      : 0;
  }

  calculatePlaceQuantity() {
    merge(
      this.orderForm.controls.packageQuantityLimit.valueChanges,
      this.orderForm.controls.packageQuantity.valueChanges,
    )
      .pipe(
        map(() => {
          const { packageQuantityLimit = 0, packageQuantity = 0 } =
            this.orderForm.getRawValue();
          return [packageQuantityLimit, packageQuantity];
        }),
        tap(([packageQuantityLimit, packageQuantity]) => {
          this.orderForm.controls.cargoPlaceQuantity.setValue(
            this.getCalculatedQuantity(packageQuantityLimit, packageQuantity),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  updatePackageDetails(packageTypeCode: Nullable<string>) {
    const packageDetails = this.catalogs.packageTypesMap.get(
      packageTypeCode || '',
    );
    if (packageDetails) {
      this.orderForm.controls.packageTypeJson.patchValue(packageDetails, {
        emitEvent: false,
      });
    } else {
      this.orderForm.controls.packageTypeJson.reset();
      this.orderForm.controls.packageTypeJson.markAsDirty();
    }
    const packageCargoPlace = packageDetails?.cargoPlaceQuantityJson.find(
      place => place.cargoPlaceId === this.cargoPlaceJson.value.id,
    );
    this.orderForm.controls.packageQuantityLimit.setValue(
      packageCargoPlace?.quantity ?? 0,
    );
  }

  get cargoPlaceJson() {
    return this.orderForm.controls.cargoPlaceJson;
  }

  updateCargoPlaceDetails(
    cargoPlaceId: Nullable<string>,
    trailerType: TrailerType,
  ) {
    const cargoPlaceDetails = this.catalogs.cargoPlaceMap.get(
      cargoPlaceId || '',
    );
    if (cargoPlaceDetails) {
      const trailer = getCargoPlaceQuantityByTrailerType(trailerType);
      this.cargoPlaceJson.patchValue(cargoPlaceDetails, { emitEvent: false });
      this.cargoPlaceQuantityLimit.setValue(
        trailer ? cargoPlaceDetails?.[trailer] ?? null : null,
      );
    } else {
      this.cargoPlaceJson.reset(undefined, { emitEvent: false });
      this.cargoPlaceJson.markAsDirty();
      this.cargoPlaceQuantityLimit.setValue(null);
    }
  }

  disableDefaultFields() {
    const controls = this.orderForm.controls;
    const options = { emitEvent: false };
    controls.packageQuantityLimit.disable(options);
    controls.packageTypeJson.controls.name.disable({
      emitEvent: false,
      onlySelf: true,
    });
  }

  disablePlaceQuantity() {
    this.hasBox$
      .pipe(
        tap(hasBox => {
          const control = this.orderForm.controls.cargoPlaceQuantity;
          if (hasBox) {
            control.disable();
          } else {
            control.enable();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  setCargoPlaceLimit() {
    const cargo = this.store.selectSnapshot(CargoState.getSelectedCargo);
    const trailer = getCargoPlaceQuantityByTrailerType(
      cargo?.trailerType as TrailerType,
    );
    const cargoPlaceJson = this.cargoPlaceJson.getRawValue();
    if (trailer) {
      this.cargoPlaceQuantityLimit.setValue(cargoPlaceJson[trailer]);
    }
  }

  ngOnInit(): void {
    this.disableDefaultFields();
    this.hasBox$ = this.orderForm.controls.box.valueChanges.pipe(
      startWith(this.orderForm.controls.box.getRawValue()),
      map(value => value ?? false),
      shareReplay(),
    );

    this.disablePlaceQuantity();
    this.onCargoPlaceTypeChange();
    this.onPackageTypeChange();
    this.calculatePlaceQuantity();
    this.setCargoPlaceLimit();
  }
}
