import { DestroyRef, Injectable } from '@angular/core';
import { FarmService } from '@shared/services/farm.service';
import { ClientService } from '@shared/services/client.service';
import { CargoPlaceService } from '@shared/services/cargo-place.service';
import { shareReplay, combineLatest, tap, map } from 'rxjs';
import { BuyerService } from '@shared/services/buyer.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlowerTypeService } from '@shared/services/flower-type.service';
import { SellerService } from '@shared/services/seller.service';
import { PackageTypeService } from '@shared/services/package-type.service';
import { RateService } from '@shared/services/rate.service';
import { prepareCatalog } from '@shared/utils/catalogs';
import { PackageType } from '../../../types/package-type';
import { CargoPlace } from '../../../types/cargo-place';
import { Farm } from '../../../types/farm';
import { Client } from '../../../types/client';
import { CatalogDetails } from '../../../types/catalog';
import { Rate } from '../../../types/rate';
import { Seller } from '../../../types/seller';
import { FlowerType } from '../../../types/flower-type';

@Injectable()
export class CatalogsService {
  farms$ = this.farmService.getAll().pipe(
    map(value => prepareCatalog(this.asCatalogDetails(value), true)),
    tap(value => {
      this.farmMap = this.farmService.toFarmMap(value, 'id');
    }),
    shareReplay(),
  );

  clients$ = this.clientService.getAll().pipe(
    map(value => prepareCatalog(this.asCatalogDetails(value), true)),
    tap(value => {
      this.clientMap = this.clientService.toClientMap(value, 'id');
    }),
    shareReplay(),
  );

  cargoPlaces$ = this.cargoPlaceService.getAll().pipe(
    map(value => prepareCatalog(this.asCatalogDetails(value))),
    tap(value => {
      this.cargoPlaceMap = this.cargoPlaceService.toCargoPlaceMap(
        value,
        'code',
      );
    }),
    shareReplay(),
  );

  buyers$ = this.buyerService.getAll().pipe(
    map(value => prepareCatalog(this.asCatalogDetails(value), true)),
    shareReplay(),
  );

  flowerTypes$ = this.flowerTypeService.getAll().pipe(
    map(value => prepareCatalog(this.asCatalogDetails(value))),
    tap(value => {
      this.flowerTypesMap = this.flowerTypeService.toFlowerTypeMap(value, 'id');
    }),
    shareReplay(),
  );

  sellers$ = this.sellerService.getAll().pipe(
    map(value => prepareCatalog(this.asCatalogDetails(value), true)),
    tap(value => {
      this.sellerMap = this.sellerService.toSellerMap(value, 'id');
    }),
    shareReplay(),
  );

  packageTypes$ = this.packageTypeService.getAll().pipe(
    map(value => prepareCatalog(this.asCatalogDetails(value))),
    tap(value => {
      this.packageTypesMap = this.packageTypeService.toPackageTypeMap(
        value,
        'code',
      );
    }),
    shareReplay(),
  );

  rates$ = this.rateService.getAll().pipe(
    map(value => prepareCatalog(this.asCatalogDetails(value))),
    tap(value => {
      this.rateMap = this.rateService.toRateMap(value);
    }),
    shareReplay(),
  );

  packageTypesMap: Map<string, PackageType> = new Map();
  cargoPlaceMap: Map<string, CargoPlace> = new Map();
  farmMap: Map<string, Farm> = new Map();
  clientMap: Map<string, Client> = new Map();
  rateMap: Map<string, Rate> = new Map();
  sellerMap: Map<string, Seller> = new Map();
  flowerTypesMap: Map<string, FlowerType> = new Map();
  constructor(
    private readonly farmService: FarmService,
    private readonly clientService: ClientService,
    private readonly cargoPlaceService: CargoPlaceService,
    private readonly buyerService: BuyerService,
    private readonly flowerTypeService: FlowerTypeService,
    private readonly sellerService: SellerService,
    private readonly packageTypeService: PackageTypeService,
    private readonly rateService: RateService,
    private readonly destroyRef: DestroyRef,
  ) {}

  private asCatalogDetails<T>(list: T[]): CatalogDetails<T> {
    return {
      updated: '',
      list,
    };
  }

  preload() {
    combineLatest([
      this.farms$,
      this.clients$,
      this.cargoPlaces$,
      this.buyers$,
      this.flowerTypes$,
      this.sellers$,
      this.packageTypes$,
      this.rates$,
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
